import {
  DisplayMetadataBrowserData,
  DisplayMetadataMemoryData,
  DisplayMetadataScreenData,
  DisplayMetadataWindowData,
} from '@admefy/domain';

export async function getBrowserMetadata(): Promise<{
  screenData: DisplayMetadataScreenData;
  windowData: DisplayMetadataWindowData;
  browserData: DisplayMetadataBrowserData;
  memoryData: DisplayMetadataMemoryData;
} | null> {
  if (typeof window === 'undefined') {
    return null;
  }

  // Información de la pantalla
  const screenData = {
    screenWidth: window.screen.width, // Ancho total de la pantalla
    screenHeight: window.screen.height, // Alto total de la pantalla
    availableWidth: window.screen.availWidth, // Ancho disponible para la ventana del navegador
    availableHeight: window.screen.availHeight, // Alto disponible para la ventana del navegador
    colorDepth: window.screen.colorDepth, // Profundidad de color en bits
    pixelDepth: window.screen.pixelDepth, // Profundidad de píxel
  };

  // Tamaño de la ventana (viewport)
  const windowData = {
    innerWidth: window.innerWidth, // Ancho interior del viewport (sin bordes)
    innerHeight: window.innerHeight, // Alto interior del viewport
    outerWidth: window.outerWidth, // Ancho total de la ventana del navegador
    outerHeight: window.outerHeight, // Alto total de la ventana del navegador
  };

  // Información del navegador
  const browserData = {
    appName: navigator.appName, // Nombre del navegador
    userAgent: navigator.userAgent, // Agente del usuario
    language: navigator.language, // Idioma del navegador
    platform: navigator.platform, // Plataforma del SO (Windows, macOS, Linux, etc.)
    online: navigator.onLine, // Estado de conexión (en línea o fuera de línea)
    cookiesEnabled: navigator.cookieEnabled, // Cookies habilitadas
    doNotTrack: navigator.doNotTrack, // Preferencia de seguimiento del usuario
    hardwareConcurrency: navigator.hardwareConcurrency,
  };

  // Información de memoria (solo en navegadores modernos)
  const memoryData =
    'deviceMemory' in navigator
      ? { deviceMemory: navigator.deviceMemory + ' GB' } // Memoria RAM aproximada en GB
      : { deviceMemory: 'Not available' };

  return {
    screenData,
    windowData,
    browserData,
    memoryData,
  };
}
